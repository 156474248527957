import { UniqueIdentifier } from "hooks/uniqueIdentifier";
import * as React from "react";

const POPUP_INFO_COMPONENT_KEY = "info";

type PopupTipInfoProps = React.SVGAttributes<SVGElement> & {
  isHovered?: boolean;
  popupTipIdentifier: UniqueIdentifier;
  iconColor?: "primary" | "secondary";
};

export const PopupTipInfo = (props: PopupTipInfoProps) => {
  const { popupTipIdentifier, isHovered, iconColor, ...rest } = props;
  const color = iconColor === "primary" ? "#002677" : "#fff";
  const hoverColor = iconColor === "primary" ? "#196ecf" : "#dadee9";

  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      focusable={false}
      aria-hidden={true}
      {...rest}
    >
      <defs>
        <path
          d="M9 0C4.036 0 0 4.036 0 9s4.036 9 9 9 9-4.036 9-9-4.036-9-9-9zm.994 13.921H8V7h2v6.921h-.006zM9.065 5.67C8.505 5.67 8 5.177 8 4.585 8 3.997 8.5 3.5 9.085 3.5c.6 0 1.085.493 1.085 1.085S9.665 5.67 9.065 5.67z"
          id={popupTipIdentifier.getChildId(POPUP_INFO_COMPONENT_KEY)}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={popupTipIdentifier.getChildId("mask")} fill="#fff">
          <use
            xlinkHref={`#${popupTipIdentifier.getChildId(
              POPUP_INFO_COMPONENT_KEY
            )}`}
          />
        </mask>
        <path
          fill={!isHovered ? color : hoverColor}
          mask={`url(#${popupTipIdentifier.getChildId("mask")})`}
          d="M0 0h18v18H0z"
        />
      </g>
    </svg>
  );
};

PopupTipInfo.defaultProps = {
  iconColor: "primary",
} as Partial<PopupTipInfoProps>;

export default PopupTipInfo;
