import * as React from "react";

function SvgIconAlert(props: React.SVGProps<SVGSVGElement>) {
  const { children, ...others } = props;
  return (
    <svg viewBox="0 0 48 48" {...others}>
      {children || null}
      <circle
        className="at-0 otc-2 ttc-2 ttcw-1"
        fill="#FFF"
        cx={24}
        cy={24}
        r={24}
      />
      <path
        className="at-2 otc-1 ttc-1"
        fill="#002677"
        d="M22.29 4.99L6.68 32.03c-.76 1.32.19 2.96 1.71 2.96h31.22c1.52 0 2.47-1.65 1.71-2.96L25.71 4.99c-.76-1.32-2.66-1.32-3.42 0z"
      />
      <path
        className="at-3 ot-1 otc-2"
        fill="#00BED5"
        d="M25.99 25.3h-4V13.09l4-1.78V25.3zm-2.02 2.02c-1.23 0-2.25 1.02-2.25 2.25s1.02 2.25 2.22 2.25c1.26 0 2.28-.99 2.28-2.25 0-1.23-1.02-2.25-2.25-2.25z"
      />
    </svg>
  );
}

export default SvgIconAlert;
