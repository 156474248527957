import * as React from "react";

type PopupTipQuestionProps = React.SVGAttributes<SVGElement> & {
  isHovered?: boolean;
  iconColor?: "primary" | "secondary";
};

export const PopupTipQuestion = (props: PopupTipQuestionProps) => {
  const { isHovered, iconColor, ...rest } = props;
  const color = iconColor === "primary" ? "#002677" : "#fff";
  const hoverColor = iconColor === "primary" ? "#196ecf" : "#fff";
  const hoverOpacity = iconColor === "primary" ? 1 : 0.85;

  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      aria-hidden={true}
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm.9 15.3H8.1v-1.8h1.8v1.8zm1.863-6.975l-.81.828C10.305 9.81 9.9 10.35 9.9 11.7H8.1v-.45c0-.99.405-1.89 1.053-2.547l1.116-1.134A1.76 1.76 0 0010.8 6.3c0-.99-.81-1.8-1.8-1.8s-1.8.81-1.8 1.8H5.4c0-1.989 1.611-3.6 3.6-3.6s3.6 1.611 3.6 3.6c0 .792-.324 1.512-.837 2.025z"
          opacity={!isHovered ? 1 : hoverOpacity}
          fill={!isHovered ? color : hoverColor}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

PopupTipQuestion.defaultProps = {
  iconColor: "primary",
} as Partial<PopupTipQuestionProps>;

export default PopupTipQuestion;
