import * as React from "react";

export const PopupTipClose = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 10 10"
      aria-hidden={true}
      focusable={false}
      {...props}
    >
      <path
        d="M10 1.007L8.993 0 5 3.993 1.007 0 0 1.007 3.993 5 0 8.993 1.007 10 5 6.007 8.993 10 10 8.993 6.007 5z"
        fill="#196ECF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PopupTipClose;
