import * as React from "react";
import classNames from "classnames";

export interface TabPanelProps {
  isActive?: boolean;
}
export type TabPanelType = React.FunctionComponent<
  TabPanelProps & React.HTMLAttributes<HTMLDivElement>
>;

/**
 * This is a tab
 * @param {TabPanelProps} props
 */
const TabPanel: React.FunctionComponent<
  TabPanelProps & React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { isActive, className, children, ...other } = props;

  let tabClasses = classNames(
    {
      "tds-tab__tab-panel": true,
      "tds-tab__tab-panel--active": isActive,
    },
    className
  );

  return (
    <div className={tabClasses} {...other}>
      {children}
    </div>
  );
};

export default TabPanel;
