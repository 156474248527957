import classNames from "classnames";
import React, { useRef, useState, useEffect } from "react";
import PopupTipQuestion from "./PopupTipQuestion";
import PopupTipInfo from "./PopupTipInfo";
import PopupTipClose from "./PopupTipClose";
import { useUniqueId } from "../../hooks";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

export interface PopupTipProps {
  /**
   * The title that appears above the popup tip's content.
   *
   * @type {string}
   * @memberof PopupTipProps
   */
  title?: string;

  /**
   * A brief text description that will show up next to the popup tip.
   * A labelText is encouraged. popup tips without a labelText will announce "More info popup tip" to screen readers.
   *
   * @type {string}
   * @memberof PopupTipProps
   */
  labelText?: string;

  /**
   * Unique key to ensure we keep uniqueness across different popup tips if they are on the same page.
   * This will be used to create all the id's needed for the content and accessibility requirements.
   *
   * @type {number}
   * @memberof PopupTipProps
   */
  contentId?: number;

  /**
   * The popup tip icon that will show either an "i" (info) or a "?" (question mark).
   *
   * @type {"info" | "question"}
   * @memberof PopupTipProps
   */
  icon?: "info" | "question";

  /**
   * The position of the popup tip relative to the popup tip's icon.
   *
   * @type {"top" | "bottom" | "left" | "right"}
   * @memberof PopupTipProps
   */
  placement?: "top" | "bottom" | "left" | "right";
  /**
   * The position of the popup tip's arrow. '9' would show the arrow at 9 o'clock (the left-hand side). This can be used instead of placement to handle specific positioning.
   *
   * @type {1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}
   * @memberof PopupTipProps
   */
  pointer?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

  /**
   * The color of the popup tip's icon. 'primary' for light backgrounds and 'secondary' for dark.
   *
   * @type {"primary" | "secondary"}
   * @memberof PopupTipProps
   */
  iconColor?: "primary" | "secondary";
}

const PopupTip: React.FunctionComponent<
  PopupTipProps & React.HTMLProps<HTMLSpanElement>
> = (props) => {
  const {
    contentId,
    labelText,
    iconColor,
    className,
    icon,
    placement,
    pointer,
    title,
    children,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const popupTipId = useUniqueId("popup-tip", contentId);
  const containerRef = useRef<HTMLSpanElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  const popupTipRef = useRef<HTMLSpanElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  let popupClassNames = classNames(className, {
    "tds-popup-tip": true,
    "tds-focus-dark-background": iconColor === "secondary",
  });

  const titleClasses = classNames({
    "tds-popup-tip__title": true,
    "tds-margin-none-top": true,
    "tds-margin-sm-bottom": true,
    "tds-sr-only": title === undefined,
  });

  const contentClasses = classNames({
    "tds-popup-tip__content": true,
    "tds-popup-tip__content-empty": title === undefined,
  });

  useEffect(() => {
    // Handles clicking outside the PopupTip
    const handleDocumentClick = (e: any) => {
      if (e.target === iconRef.current) {
        return;
      }
      const iconClicked = (
        (e.composedPath !== undefined && e.composedPath()) ||
        []
      ).some(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (p: any) => p === iconRef.current
      );
      if (iconClicked) {
        return;
      }

      if (popupTipRef.current !== null && iconRef.current !== null) {
        if (
          e.target !== iconRef.current &&
          !popupTipRef.current?.contains(e.target)
        ) {
          setOpen(false);
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (
        e.key === "Tab" &&
        !popupTipRef.current?.contains(document.activeElement)
      ) {
        setOpen(false);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (open && titleRef && titleRef.current) {
      titleRef.current.focus();
    }
  }, [open]);

  const popupTitle =
    title !== undefined
      ? title
      : !labelText
      ? "More info Popup Tip"
      : labelText;

  const PLACEMENT = {
    1: "bottom-end",
    2: "left-start",
    3: "left",
    4: "left-end",
    5: "top-end",
    6: "top",
    7: "top-start",
    8: "right-end",
    9: "right",
    10: "right-start",
    11: "bottom-start",
    12: "bottom",
  };

  const PopupContent = () => {
    return (
      <span
        ref={popupTipRef}
        role="dialog"
        tabIndex={!open ? -1 : undefined}
        {...rest}
      >
        <h3 className={titleClasses} tabIndex={-1} ref={titleRef}>
          {popupTitle}
        </h3>
        <button
          className="tds-popup-tip__close"
          onClick={() => setOpen(false)}
          ref={closeButtonRef}
        >
          <span className="tds-sr-only">Close</span>
          <PopupTipClose />
        </button>
        <div className={contentClasses}>{children}</div>
      </span>
    );
  };

  return (
    <span
      className={popupClassNames} ref={containerRef}>
      {labelText && (
        <span
          id={popupTipId.getChildId("text")}
          className={`tds-popup-tip__button-text${
            iconColor === "secondary"
              ? " tds-popup-tip__button-text-secondary"
              : ""
          }`}
        >
          {labelText}
        </span>
      )}
        <span className="tds-popup-tip__button-container">
          <Tippy
            content={<PopupContent />}
            interactive={true}
            visible={open}
            onClickOutside={() => setOpen(false)}
            theme="light-border"
            placement={pointer ? PLACEMENT[Number(pointer)] : placement}
          >
          <button
            tabIndex={0}
            className="tds-popup-tip__button"
            onClick={() => setOpen(!open)}
            ref={iconRef}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            aria-expanded={open}
            aria-label={!labelText ? "More info Popup Tip" : undefined}
            aria-labelledby={
              labelText ? popupTipId.getChildId("icon") : undefined
            }
            aria-controls={popupTipId.getId()}
            aria-describedby={popupTipId.getId()}
            id={popupTipId.getChildId("button")} // Needed for test at this point...
          >
            {icon === "info" ? (
              <PopupTipInfo
                iconColor={iconColor === "secondary" ? "secondary" : undefined}
                isHovered={hover}
                popupTipIdentifier={popupTipId}
              />
            ) : (
              <PopupTipQuestion
                iconColor={iconColor === "secondary" ? "secondary" : undefined}
                isHovered={hover}
              />
            )}
          </button>
        </Tippy>
      </span>
    </span>
  );
};

PopupTip.defaultProps = {
  // NOTE: having "top" in this prop as the default would expose a bug within Storybook that causes intermittent loading issues.
  // -- See https://github.optum.com/uhc-digital/tempo-design-system/pull/146 for details
  placement: "bottom",
  icon: "info",
  iconColor: "primary",
} as Partial<PopupTipProps>;

export default PopupTip;
