import React from "react";

import { usePageLoadTracking } from "../../tracking";

export interface PageTrackerProps {
  pageName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

/**
 * A tracking container
 */
const PageTracker: React.FunctionComponent<PageTrackerProps> = ({
  pageName,
  children,
}) => {
  usePageLoadTracking({
    pageName: pageName,
  });
  return children;
};

export default PageTracker;
